//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _getorderInfo } from "@/api/maintenance";
export default {
  props: ["tableData"],
  data() {
    return {
      list: [],
    };
  },
  methods: {
    tableHeaderColor({ row, rowIndex, column, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color:#e8f4ff;font-weight: 400;border-radius: 4px;color: #5f8bcf;height:60px;font-size:15px;font-family: Microsoft YaHei, Microsoft YaHei-Regular;padding:5px 0";
      }
    },
    tabClick(row) {
      this.$router.push({
        path: "/main/maintenance/order/mainjg",
        query: {type:row.orderStatus, orderId: row.mocId ,spDid:row.spDid},
      });
    },

  },
};
