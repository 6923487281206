//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _getUserList, _gettree, _getcode } from '@/api/organizational.js'
import {
  _getscansp,
  _getbind,
  _getdebindment,
  _getscandeptadmin,
  _getchecksp,
  _getupdatebind,
  _getrenewal,
  _getGetConfirms
} from '@/api/service.js'

// let day = 7;
export default {
  data() {
    return {
      // 结束日期不能大于开始日期
      pickerOptions: {
        disabledDate: time => {
          let endTimes = this.form.endTime
          return time.getTime() < new Date(endTimes).getTime()
        },
      },
      title: '', //标题
      dialogVisible: false, //控制弹窗显隐
      curcityist: [], //单位列表
      options: [], //服务商列表
      paymentList: [],
      //form表单数据
      form: {
        spdid: '', //服务商id
        name: '',
        id: '',
        checkedlists: [],
        opComment: '',
        startTime: '',
        endTime: '',
      },
      viewBtn: false,
    }
  },
  methods: {
    //显示隐藏弹窗
    changeDiaLog(title, params, type) {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
      this.dialogVisible = true
      this.getcode()
      //续约
      this.form.id = params.id //数据行id
      this.title = title
      let that = this
      // 获取服务商列表
      this.remoteMethod(params.spDname)
      _gettree({ id: '', child: false }).then(res => {
        this.curcityist = JSON.parse(JSON.stringify(res.data))
      })
      that.form.checkedlists = []
      params.leaders.forEach((item, index) => {
        that.form.checkedlists.push({
          leadersddId: '',
          leadersId: '',
          uesrlist: [], //负责人列表
        })
        that.form.checkedlists[index].leadersddId = item.dDid
        that.form.checkedlists[index].leadersId = item.dUid

        _getGetConfirms({ did: item.dDid }).then(res => {
          that.form.checkedlists[index].uesrlist = res.data
          this.$forceUpdate()
        })
        //shz 1. 14  注释   上面新增
        // _getscandeptadmin({ did: item.dDid }).then(res => {
        //   that.form.checkedlists[index].uesrlist = res.data
        //   this.$forceUpdate()
        // })
      })
      this.form.spdid = params.spDid //服务商的spDid
      this.form.payType = params.payType
      this.form.startTime = params.startTime + '.000Z' //开始时间
      this.form.endTime = params.endTime + '.000Z' //结合时间
    },
    // 服务商名称的筛选事件
    remoteMethod(query) {
      this.form.name = query
      _getscansp({ spName: this.form.name }).then(res => {
        this.options = res.data
      })
    },
    // 结算方式的下拉列表
    getcode() {
      let from = {
        para: 'jsfs',
      }
      _getcode(from).then(res => {
        this.paymentList = res.data
      })
    },
    // 所有的部门数据
    gettree() {
      _gettree({ id: '', child: false }).then(res => {})
    },
    // 续约事件
    save() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.viewBtn = true
          let asfd = {
            id: this.form.id,
            endTime: this.form.endTime,
          }
          _getrenewal(asfd)
            .then(res => {
              if (res.code == '1') {
                this.$emit('query')
                this.dialogVisible = false
                this.viewBtn = false
              } else {
                this.viewBtn = false
              }
            })
            .catch(() => {
              this.viewBtn = false
            })
        }
      })
    },
    // 弹窗关闭的回调
    close() {
      this.title = ''
      ;(this.form = {
        spdid: '', //服务商id
        name: '',
        id: '',
        payType: '',
        checkedlists: [],
        opComment: '',
        startTime: '',
        endTime: '',
      }),
        (this.options = [])
      this.disab = false
      this.serveBtn = false
      this.$refs['form'].resetFields()
    },
  },
}
