import { request, requestFd } from '../core/request'

// 获取服务商工单统计
export function _getservic(params) {
    console.log('获取服务商工单统计')
    return request({
        url:'/ananops/api/v1/pri/supervision/serviceOddNumber',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
  }

  // 获取需求方工单统计
export function _getoddNumber (params) {
    console.log('获取需求方工单统计')
    return request({
        url:'/ananops/api/v1/pri/supervision/oddNumber',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
  }

    // 三个饼图
export function _getPlatWorkOrder (params) {
    console.log('三个饼图')
    return request({
        url:'/ananops/api/v1/pri/supervision/PlatWorkOrder',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: true // 是否加token
    })
  }
  

