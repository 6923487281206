//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _getorderinfo, _getorderNumber } from '@/api/disabilities.js'
import { _getcode } from '@/api/organizational'
import {
  _platOrderList,
  _checkexportReport,
  _checkexportworkreport,
  _checkexportacceptreport,
} from '@/api/check'
import OrderTable from './OrderTable.vue'
import FileSaver from 'file-saver'
import { saveAs } from 'file-saver'
import XLSX from 'xlsx'

export default {
  components: {
    OrderTable,
  },
  data() {
    return {
      // 结束日期不能大于开始日期
      pickerOptions: {
        disabledDate: (time) => {
          let starTime = this.formInline.startTime
          if (starTime) {
            console.log(time.getTime())
            return time.getTime() < new Date(starTime).getTime()
          }
        },
      },
      // 开始日期不能小于结束日期
      startPickerOptions: {
        disabledDate: (time) => {
          let endTime = this.formInline.endTime
          if (endTime) {
            return time.getTime() > new Date(endTime).getTime()
          }
        },
      },
      // editableTabsValue: '10',//工单状态
      pageSize: 10, //没页几条
      currentNumber: 1, //当前页数
      total: 0, //总条数
      formInline: {
        orderId: '',
        reportLevel: '',
        currentNumber: this.currentNumber,
        page: this.pageSize,
        orderStatus: '',
        startTime: '',
        endTime: '',
      },
      type: '10', //标签状态值
      options: [],
      optionss: [],
      gdatList: [],
      list: [],
      tableData: [],
      activeName: '',
    }
  },
  created() {
    this.platOrderList({ currentNumber: 1, pageSize: 10 })
    this.getcode()
    // ;(this.type = this.$route.query.selected
    //   ? this.$route.query.selected
    //   : '10'),
    //   this.getorderinfo({
    //     type: this.type,
    //     id: '',
    //     orderId: '',
    //     reportLevel: '',
    //     current: 1,
    //     page: 10,
    //   })
  },
  methods: {
    //导出
    downloads(val) {
      let from = {
        orderNumber: this.formInline.orderId,
        reportLevel: this.formInline.reportLevel,
        orderStatus: this.formInline.orderStatus,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
      }
      if (val == 'shigong') {
        _checkexportworkreport(from).then((res) => {
          console.log(res)
          // const link = document.createElement('a')
          const blob = new Blob([res], { type: 'application/vnd.ms-excel' })

          const a = document.createElement('a')
          // 利用URL.createObjectURL()方法为a元素生成blob URL
          a.href = URL.createObjectURL(blob)
          // 设置文件名，目前只有Chrome和FireFox支持此属性
          // const filename = window.vm.$cookies.get('filename')
          const filename = '维修施工单'
          a.download = filename
          a.click()
        })
      } else if (val == 'yanshou') {
        _checkexportacceptreport(from).then((res) => {
          console.log(res)
          // const link = document.createElement('a')
          const blob = new Blob([res], { type: 'application/vnd.ms-excel' })

          const a = document.createElement('a')
          // 利用URL.createObjectURL()方法为a元素生成blob URL
          a.href = URL.createObjectURL(blob)
          // 设置文件名，目前只有Chrome和FireFox支持此属性
          // const filename = window.vm.$cookies.get('filename')
          const filename = '维修工程验收单'
          a.download = filename
          a.click()
        })
      }
    },

    //导出
    download() {
      let from = {
        orderNumber: this.formInline.orderId,
        reportLevel: this.formInline.reportLevel,
        orderStatus: this.formInline.orderStatus,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        // type: this.type,
        // id: '',
      }
      _checkexportReport(from).then((res) => {
        console.log(res)
        // const link = document.createElement('a')
        const blob = new Blob([res], { type: 'application/vnd.ms-excel' })

        const a = document.createElement('a')
        // 利用URL.createObjectURL()方法为a元素生成blob URL
        a.href = URL.createObjectURL(blob)
        // 设置文件名，目前只有Chrome和FireFox支持此属性
        // const filename = window.vm.$cookies.get('filename')
        const filename = '维修工单列表'
        a.download = filename
        a.click()
      })
      //  this.$refs.OrderTable.dialogTableVisible = true

      // this.$refs.OrderTable.init()

      // .then(etout => {
      //   console.log(etout)
      //   _this.platOrderList({ currentNumber: 1, pageSize: 10 })
      // })
      // let url ='http://130.10.7.48:8002/ananops/api/v1/pri/supervision/exportreport'
      // let link = document.createElement('a')
      // link.style.display = 'none'
      // link.href = url
      // link.id = 'Adownload'
      // link.setAttribute('download', 'excel.xls') //命名可能会出现问题，格式一定和后端下载的格式一样
      // document.body.appendChild(link)
      // link.click()
      // document.getElementById('Adownload').remove()
    },

    // 查询
    query() {
      this.currentNumber = 1

      console.log(this.formInline)
      let from = {
        orderNumber: this.formInline.orderId,
        reportLevel: this.formInline.reportLevel,
        currentNumber: this.currentNumber,
        pageSize: this.pageSize,
        orderStatus: this.formInline.orderStatus,
        startTime: this.formInline.startTime,
        endTime: this.formInline.endTime,
        // type: this.type,
        // id: '',
      }
      this.platOrderList(from)
      // this.platOrderList({ currentNumber: 1, pageSize: 10 })
    },
    //获取表格数据
    platOrderList(from) {
      _platOrderList(from).then((res) => {
        // let tableData = res.data.data
        // this.tableData = tableData.map(item => {
        //   return {
        //     ...item,
        //     registererTimeFir : item.registererTime.split("T")[0],
        //     registererTimeSec : item.registererTime.split("T")[1],
        //   }

        // })
        this.tableData = res.data.data
        this.total = res.data.total
      })
    },
    // 代码表
    getcode() {
      // let from = {
      //   para: 'gdzt',
      // }
      // _getcode(from).then(res => {
      //   if (res.code == '1') {
      //     this.getorderNumber()
      //     this.gdatList = res.data
      //   }
      // })
      // 故障等级
      _getcode({ para: 'gzdj' }).then((res) => {
        this.options = res.data
      })
      _getcode({ para: 'gdzt' }).then((res) => {
        let data = res.data
        data.splice(1, 0, { name: '待确认', id: '20' })
        this.optionss = data
      })
    },
    // 数据表
    getorderNumber() {
      _getorderNumber({}).then((res) => {
        let aaa = res.data
        if (aaa.length > 0) {
          this.list = this.gdatList.reduce((pre, cur) => {
            let target = pre.find((ee) => ee.id == cur.id)
            if (target) {
              Object.assign(target, cur)
            } else {
              pre.push(cur)
            }
            return pre
          }, aaa)
        } else {
          this.list = this.gdatList
        }
      })
    },
    handleClick(tab) {
      this.type = tab.name
      this.current = 1
      this.pageSize = 10
      let from = {
        type: tab.name,
        id: '',
        orderId: '',
        reportLevel: '',
        current: 1,
        page: 10,
      }
      this.getorderinfo(from)
    },
    // 分页 更改每页几条
    handleSizeChange(val) {
      this.currentNumber = 1
      this.pageSize = val
      let from = {
        currentNumber: this.currentNumber,
        pageSize: this.pageSize,
        orderNumber: this.formInline.orderId,
        reportLevel: this.formInline.reportLevel,
        // type: this.type,
        // orderId: '',
        // reportLevel: '',
        // id: '',
      }
      this.platOrderList(from)
      // this.getorderinfo(from)
    },
    //更改几页
    handleCurrentChange(val) {
      this.currentNumber = val
      let from = {
        currentNumber: this.currentNumber,
        pageSize: this.pageSize,
        orderNumber: this.formInline.orderId,
        reportLevel: this.formInline.reportLevel,
        // type: this.type,
        // orderId: '',
        // reportLevel: '',
        // id: '',
      }
      this.platOrderList(from)
      // this.getorderinfo(from)
    },
  },
  mounted() {
    // this.editableTabsValue = this.$route.query.selected
    //   ? this.$route.query.selected
    //   : '10'
  },
}
