//
//
//
//
//
//
//
//
//
//
//
//

import Order from "./order.vue"
import Serve from "./serve.vue"
export default {
   components: {
    Order,
    Serve,
  },
  data() {
    return {
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
  },
};
