//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as echarts from "echarts";
import { _oddNumberSum} from "@/api/check.js";

export default {
  data() {
    return {
      formInline: {
        startTime: "",
        endTime: "",
      },
      legendData:[],
      timeData:[],
      serveData:[]
    };
  },

  mounted() {
      this.oddNumberSum(this.formInline)
  },
  methods: {
    query(){
      this.oddNumberSum(this.formInline)
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let chartDom = document.getElementById("myChart");
      let myChart = echarts.init(chartDom);
      // 绘制图表
      myChart.setOption({
        // backgroundColor: "#323a5e",
        tooltip: {
          trigger: "axis",
          axisPointer: {
            // 坐标轴指示器，坐标轴触发有效
            type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
          },
        },
        grid: {
          left: "2%",
          right: "4%",
          bottom: "14%",
          top: "16%",
          containLabel: true,
        },
        legend: {
          data: this.legendData,
          right: 10,
          top: 12,
          // textStyle: {
          //   color: "#fff",
          // },
          itemWidth: 12,
          itemHeight: 10,
          // itemGap: 35
        },
        xAxis: {
          type: "category",
          data:this.timeData,
          axisLine: {
            // lineStyle: {
            //   color: "white",
            // },
          },
          axisLabel: {
            // interval: 0,
            // rotate: 40,
            textStyle: {
              fontFamily: "Microsoft YaHei",
            },
          },
        },

        yAxis: {
          type: "value",
          axisLine: {
            show: false,
            // lineStyle: {
            //   color: "white",
            // },
          },
          splitLine: {
            show: true,
            // lineStyle: {
            //   color: "rgba(255,255,255,0.3)",
            // },
          },
          axisLabel: {},
        },
        dataZoom: [
          {
            show: true,
            height: 12,
            xAxisIndex: [0],
            bottom: "8%",
            start: 10,
            end: 90,
            handleIcon:
              "path://M306.1,413c0,2.2-1.8,4-4,4h-59.8c-2.2,0-4-1.8-4-4V200.8c0-2.2,1.8-4,4-4h59.8c2.2,0,4,1.8,4,4V413z",
            handleSize: "110%",
            handleStyle: {
              color: "#d3dee5",
            },
            textStyle: {
              color: "#fff",
            },
            borderColor: "#90979c",
          },
          {
            type: "inside",
            show: true,
            height: 15,
            start: 1,
            end: 35,
          },
        ],
        series: this.serveData,
      });
    //   var app = {
    //   currentIndex: -1,
    // };
    // setInterval(function () {
    //   var dataLen = option.series[0].data.length;
    //   // 取消之前高亮的图形
    //   myChart.dispatchAction({
    //     type: "downplay",
    //     seriesIndex: 0,
    //     dataIndex: app.currentIndex,
    //   });
    //   app.currentIndex = (app.currentIndex + 1) % dataLen;
    //   //console.log(app.currentIndex);
    //   // 高亮当前图形
    //   myChart.dispatchAction({
    //     type: "highlight",
    //     seriesIndex: 0,
    //     dataIndex: app.currentIndex,
    //   });
    //   // 显示 tooltip
    //   myChart.dispatchAction({
    //     type: "showTip",
    //     seriesIndex: 0,
    //     dataIndex: app.currentIndex,
    //   });
    // }, 1000);
    },
    oddNumberSum(from){
      this.legendData = [];
      _oddNumberSum(from).then((res)=>{
        // 聚合数据
        res.data.workOrder.map((item)=>{
          this.legendData = item.data.map((iovs)=>{
            return iovs.name
          })
        })
        // X轴数据
        this.timeData = res.data.workOrder.map((item)=>{
          return item.dept
        })
        // 聚合大数据
        res.data.workOrder.map((item)=>{
          this.serveData = []
          item.data.forEach((iovs)=>{
            this.serveData.push( {
            name: iovs.name,
            type: "bar",
            barWidth: "15%",
            
            data: [],
          },)
          })
        })
        this.serveData.forEach((item)=>{
          res.data.workOrder.forEach((iovs)=>{
            iovs.data.forEach((desp)=>{
              if(item.name == desp.name){
                item.data.push(desp.value)
              }
            })
          })
        })
      this.drawLine()
      })
    }
  },
};
