//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _getcode } from '@/api/organizational'
import { _confirm } from '@/api/auditPtf'
import ElImageViewer from "element-ui/packages/image/src/image-viewer";

//获取省市区三级联动数据接口
import {
  _getProvinceList,
  _getProvinceCityList,
  _getCityCountyList,
  _getSubmitUser,
  _getgetinfoById,
  _download,
  _getStarRatePlatformStarRateInfo,
} from '@/api/province'

export default {
  data() {
    return {
      dialogVisible: false,
      dataAdd: [],
      swith: '',
      formInline: {
        name: '', //主体名称
        organType: '', //主体机构类型
        address: '', //详细地址
        contactName: '', //联系人姓名
        contactPhone: '', //联系人手机号
        email: '', //邮箱
        code: '', //社会信用代码
        regionCode: '', //市
        provinceid: '', //活动区域
        cityid: '', //省
        businessLicenseOssIds: [], //营业执照回显数组
        securityqualificationOssIds: [], //安防回显数组
      },
      ossIds: [], //提交的时候图片的ossid数组
      cityCountylist: [],
      provinceCitylist: [],
      provinceList: [],
      imgsList: [], // 文件上传列表
      newimgsList: [], // 文件上传列表
      dialogImageUrl: '', //查看大图
      disab: false,

      fileList: [], //文件数组
      curFileList: [], //ossid存储数组
      form: {
        opComment: '', //附件备注
      },
      // shows: false, //星级评定是否显示
      uploads: false, //判断是都需要上传
      showstarts: false,
    }
  },
components : {
    ElImageViewer 
  },
  created() {
    this.getcode()
    this.swith = this.$route.query.swith
    //获取省的数据
    _getProvinceList({}).then((res) => {
      this.provinceList = res.data
    })
    // _getStarRatePlatformStarRateInfo({ deptId: this.$route.query.id }).then(
    //   (resq) => {
    //     console.log(resq)
    //     if (resq.data == null) {
    //       //未提交过
    //       this.uploads = true
    //       this.showstarts = false
    //       return
    //     } else if (resq.data.result == '0') {
    //       //待评级
    //       this.disabstar = false
    //       this.uploads = false
    //       this.showstarts = false
    //       return
    //       this.btns = true
    //       this.form.opComment = resq.data.opComment
    //       resq.data.enclosures.forEach((item) => {
    //         this.curFileList.push(item.id)
    //         this.fileList.push({
    //           name: item.name,
    //           response: {
    //             data: {
    //               ossId: item.id,
    //             },
    //           },
    //         })
    //       })
    //       this.textbtn = '评定'
    //     } else if (resq.data.result == '1') {
    //       //通过
    //       this.showstarts = true
    //       this.disabstar = true
    //       this.uploads = false
    //       this.form.opComment = resq.data.opComment
    //       resq.data.enclosures.forEach((item) => {
    //         this.curFileList.push(item.id)
    //         this.fileList.push({
    //           name: item.name,
    //           response: {
    //             data: {
    //               ossId: item.id,
    //             },
    //           },
    //         })
    //       })
    //       this.textbtn = resq.data.resultName
    //     } else if (resq.data.result == '2') {
    //       //未通过
    //       this.showstarts = false
    //       return
    //       this.form.opComment = resq.data.opComment
    //       this.uploads = true
    //       resq.data.enclosures.forEach((item) => {
    //         this.curFileList.push(item.id)
    //         this.fileList.push({
    //           name: item.name,
    //           response: {
    //             data: {
    //               ossId: item.id,
    //             },
    //           },
    //         })
    //       })
    //     }
    //   },
    // )
    // 回显数据
    _getgetinfoById({ id: this.$route.query.id }).then((res) => {
      if (res.code == '1') {
        this.formInline = Object.assign(res.data)
        res.data.businessLicenseOssIds.forEach((item) => {
          this.imgsList.push({
            url:
              '/ananops/api/v1/pub/file/download?ossId=' +
              `${item.ossId}` +
              '&type=pc',
          })
        })
        res.data.securityqualificationOssIds.forEach((item) => {
          this.newimgsList.push({
            url:
              '/ananops/api/v1/pub/file/download?ossId=' +
              `${item.ossId}` +
              '&type=pc',
          })
        })
        this.disab = true
        _getProvinceCityList({ parent_id: this.formInline.provinceid }).then(
          (res) => {
            this.provinceCitylist = res.data
          },
        )
        _getCityCountyList({ parent_id: this.formInline.cityid }).then(
          (res) => {
            this.cityCountylist = res.data
          },
        )
      }
    })
  },
  methods: {
    handleClick(scope) {
      //点击查看
      console.log(scope, scope.response.data.ossId)
      window.open(
        `http://130.10.7.44:8002/ananops/api/v1/pub/file/download?ossId=${scope.response.data.ossId}&type=pc`,
        // 'http://130.10.7.107:9001/ananops/%E7%BB%B4%E4%BF%AE%E5%B7%A5%E5%8D%95%E5%88%97%E8%A1%A8.xls?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=minioadmin%2F20220222%2F%2Fs3%2Faws4_request&X-Amz-Date=20220222T033734Z&X-Amz-Expires=432000&X-Amz-SignedHeaders=host&X-Amz-Signature=398ae9530e00c0e9c55bc0ae36c85c01a023af9fc67206eb94873ef92bd6a5af'
      )
    },
     download(data) {
      const a = document.createElement('a')
      a.href = `/ananops/api/v1/pub/file/download?ossId=${data.response.data.ossId}&type=pc&opration=download`
      a.click()
      // _getStarRateDownload({
      //   ossId: 'ad8876cb136f44e48f38647a0f5b1c76',
      //   type: 'pc',
      // }).then((res) => {
      //   console.log(res)
      //   // const link = document.createElement('a')
      //   const blob = new Blob([res], { type: 'application/pdf' })

      //   const a = document.createElement('a')
      //   // 利用URL.createObjectURL()方法为a元素生成blob URL
      //   a.href = URL.createObjectURL(blob)
      //   // 设置文件名，目前只有Chrome和FireFox支持此属性
      //   // const filename = window.vm.$cookies.get('filename')
      //   // const filename = '维修工单列表'
      //   // a.download = filename
      //   a.click()
      // })
    },
    changeProvince(e) {
      //获取市的数据
      _getProvinceCityList({ parent_id: this.formInline.provinceid }).then(
        (res) => {
          this.provinceCitylist = res.data
        },
      )
    },
    changeProvinceCity(e) {
      //获取区的数据
      _getCityCountyList({ parent_id: this.formInline.cityid }).then((res) => {
        this.cityCountylist = res.data
      })
    },

    getcode() {
      let from = {
        para: 'jglx',
      }
      _getcode(from).then((res) => {
        this.dataAdd = res.data
      })
    },
    // 点击大图显示
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
     // 关闭查看器
    closeViewer() {
      this.dialogVisible = false
    },
    // 营业执照成功返回的数据
    successFile(response, file, fileList) {
      this.formInline.businessLicenseOssIds.push(response.data.ossId)
    },
    // 营业执照图片上传接口
    changeFile(file, fileList) {},
    // 营业执照移除图片
    removeFile(file, fileList) {
      this.formInline.businessLicenseOssIds.splice(file.response.data.ossId, 1)
    },
    // 安防成功返回的数据
    newsuccessFile(response, file, fileList) {
      this.formInline.securityqualificationOssIds.push(response.data.ossId)
    },
    // 安防图片上传接口
    newchangeFile(file, fileList) {},
    // 安防移除图片
    newremoveFile(file, fileList) {
      this.formInline.securityqualificationOssIds.splice(
        file.response.data.ossId,
        1,
      )
    },
    // 确定
    agree() {
      let form = {
        checkStatus: 1,
        id: this.$route.query.id,
      }
      this.$confirm('是否确定同意', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        _confirm(form).then((res) => {
          this.$message(res.msg)
          this.$router.push({
            path: '/main/platform/platforms',
          })
        })
      })
    },
    // 拒绝
    refused() {
      this.$refs.diaol.init(this.$route.query.id)
    },
  },
}
