import { request, requestFd } from '../core/request'

// 平台方审核列表接口
export function _getPerfectList(params) {
    console.log('平台方审核列表接口')
    return request({
        url:'/ananops/api/v1/pri/account/getPerfectList',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}

// 平台方审核代表未审核的接口
export function _getUnPerfectList(params) {
    console.log('平台方审核代表未审核的接口')
    return request({
        url:'/ananops/api/v1/pri/account/getUnPerfectList',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 平台方审核同意 拒绝
export function _confirm(params) {
    console.log('平台方审核同意 拒绝')
    return request({
        url:'/ananops/api/v1/pri/account/confirm',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 绑定关系列表
export function _platformSelectBindlist(params) {
    console.log('绑定关系')
    return request({
        url:'/ananops/api/v1/pri/bindsp/platformSelectBindlist',
        method: 'post',
        data: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 禁用
export function _disable(params) {
    console.log('绑定关系')
    return request({
        url:'/ananops/api/v1/pri/account/disable',
        method: 'get',
        params: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
// 启用
export function _enable(params) {
    console.log('绑定关系')
    return request({
        url:'/ananops/api/v1/pri/account/enable',
        method: 'get',
        params: params,
        showLoading: 'true',
        tokenStatus: 'false' // 是否加token
    })
}
