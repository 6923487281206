//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import BrokenLine from "./brokenLine.vue";
import FirstPieEcharts from "./FirstPieEcharts.vue";
import SecondPieEcharts from "./SecondPieEcharts.vue";
import ThirdPieEcharts from "./ThirdPieEcharts.vue";
import { _count ,_platCount} from "@/api/check.js";
import { _getPlatWorkOrder } from "@/api/Regulatory.js";
import Services from "./services.vue";
// Distribution,
export default {
  components: {
    BrokenLine,
    FirstPieEcharts,
    SecondPieEcharts,
    ThirdPieEcharts,
    Services,
  },
  data() {
    return {
      ArrData: [],
      info:{},
      types: "",
      formInline: {
        startTime: "",
        endTime: "",
      },
      apply:[],
      grade:[],
      type:[],
    };
  },
  created() {
    this.count();
    this.platWorkOrder(this.formInline);
    this.platCount()
    this.types = JSON.parse(localStorage.getItem("registerType"));
  },

  methods: {
    query(){
    this.platWorkOrder(this.formInline);
    },
    count() {
      _count({}).then((res) => {
        this.ArrData = res.data;
      });
    },
    platCount(){
      _platCount({}).then((res)=>{
        this.info = res.data
      })
    },
    platWorkOrder(from) {
      _getPlatWorkOrder(from).then((res) => {
        this.apply = res.data.Apply//设备申请
        this.grade = res.data.Grade//故障等级
        this.type = res.data.Type//故障类型
      });
    },
  },
};
