//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _platOrderList } from '@/api/check'
import FileSaver from 'file-saver'
import { saveAs } from 'file-saver'
import XLSX from 'xlsx'
const cityOptions = [
  { prop: 'registDate', label: '报障日期' },
  { prop: 'registTime', label: '报障时间' },
  // { prop: 'registererTimeFir', label: '报障日期' },
  // { prop: 'registererTimeSec', label: '报障时间' },
  { prop: 'roDname', label: '报障单位' },
  { prop: 'reportUname', label: '报障人' },
  { prop: 'reportUphone', label: '报障人电话' },
  { prop: 'engineerUname', label: '维修工程师' },
  { prop: 'engineerPhone', label: '维修工程师电话' },
  { prop: 'compleDate', label: '修复日期' },
  { prop: 'compleTime', label: '修复时间' },
  { prop: 'repareResults', label: '修复情况' },
  { prop: 'replaceEquipment', label: '是否更换设备' },
  { prop: 'checkUphone', label: '验收人电话' },
  { prop: 'checkedDate', label: '验收日期' },
  { prop: 'checkedTime', label: '验收时间' },
]
const cityOptionsq = [
  '报障日期',
  '报障时间',
  '报障单位',
  '报障人',
  '报障人电话',
  '维修工程师',
  '维修工程师电话',
  '修复日期',
  '修复时间',
  '修复情况',
  '是否更换设备',
  '验收人电话',
  '验收日期',
  '验收时间',
]

export default {
  props: ['tableData'],
  data() {
    return {
      // tableData: [],
      checkAll: false,
      checkedCities: [],
      cities: cityOptions,
      isIndeterminate: true,
      curCheckedCities: [],
    }
  },
  methods: {
    tableHeaderColor({ row, rowIndex, column, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color:#e8f4ff;font-weight: 400;border-radius: 4px;color: #5f8bcf;height:48px;font-size:15px;font-family: Microsoft YaHei, Microsoft YaHei-Regular;padding:5px 0'
      }
    },

    tabClick(row) {
      this.$router.push({
        path: '/main/report/order/ordermainjg',
        query: { type: row.orderStatus, id: row.id },
      })
    },
    init() {
      // _platOrderList({}).then(res => {
      //     this.tableData = res.data.data
      //     console.log(res.data.data)
      //   })
      //   .then(() => {
      //     this.curInit()
      //   })
    },

    curInit() {
      let val = '安安报障工单.xlsx'
      let xlsxParam = { raw: true } // 导出内容只做解析，不转换格式，可以解决导出数字过长时的科学计算
      let et = XLSX.utils.table_to_book(
        document.querySelector('#mytabss'),
        xlsxParam,
      ) //此处传入table的DOM节点
      et.Sheets.Sheet1['!cols'] = [
        {},
        { wpx: 80 },
        { wpx: 70 },
        { wpx: 70 },
        { wpx: 70 },
      ]
      let etout = XLSX.write(et, {
        bookType: 'xlsx',
        bookSST: true,
        type: 'array',
      })
      try {
        FileSaver.saveAs(
          new Blob([etout], {
            type: 'application/octet-stream',
          }),
          val,
        ) //trade-publish.xlsx 为导出的文件名
        this.dialogTableVisible = false
        // this.alllod.close()
      } catch (e) {
        console.log(e, etout)
      }
      return etout
    },
    handleCheckAllChange(val) {
      this.checkedCities = val ? cityOptionsq : []
      this.isIndeterminate = false
      this.curCheckedCities = val ? cityOptions : []
    },
    handleCheckedCitiesChange(value) {
      let checkedCount = value.length
      this.checkAll = checkedCount === this.cities.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.cities.length
      let arr = []
      cityOptions.forEach(item => {
        if (checkedCount) {
          value.forEach(str => {
            if (item.label == str) {
              arr.push(item)
            }
          })
        }
      })
      this.curCheckedCities = arr

      // console.log(this.checkedCities, value)
    },
  },
}
