//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ChangeDemandSide from "./ChangeDemandSide.vue";
import { _getcode } from "@/api/organizational";
import { _getDemandSideTableList } from "@/api/demandside.js";
export default {
  data() {
    return {
       pageSize:10,//没页几条
      current: 1,//当前页数
      total:0,//总条数
      formInline: {
        spConfimStatus: "",
      },
      director: [],
      head: [],
      options: [],
      value: "",
      input: "",
      tableData: [],
      roleType:"",
    };
  },
  created() {
    this.getcode()
    this.getServiceList({current:this.current, page:this.pageSize});
    this.roleType = JSON.parse(localStorage.getItem("roleType")); //获取用户角色类型
  },
  components: {
    ChangeDemandSide,
  },
  methods: {
    query(){
    this.getServiceList({current:this.current, page:this.pageSize});
    },
    tableHeaderColor({ row, rowIndex, column, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color:#e8f4ff;font-weight: 400;border-radius: 4px;color: #5f8bcf;height:48px;font-size:15px;font-family: Microsoft YaHei, Microsoft YaHei-Regular;padding:5px 0";
      }
    },
        //计划状态的下拉数据
    getcode(){
       let from = {
        para: "bdzt",
      };
      _getcode(from).then((res)=>{
         this.options = res.data;
      })
    },
    // 绑定
    handleClickBind(row) {
      this.$refs.add.changeDiaLog("绑定", JSON.parse(JSON.stringify(row)),3);
    },
    // 修改
    handleClickChange(row) {
      this.$refs.add.changeDiaLog("修改", JSON.parse(JSON.stringify(row)),2);
    },
    // // 查看
    // tabClick(row) {
    //   this.$refs.add.changeDiaLog("查看", JSON.parse(JSON.stringify(row)),1);
    // },
    // 查询
    searchList() {
      this.getServiceList({spConfimStatus:this.formInline.spConfimStatus,current:this.current, page:this.pageSize})
    },
    // 列表页
    getServiceList(form) {
      _getDemandSideTableList(form).then((res) => {
        if (res.code == "1") {
        this.total =res.data.total
          let asas = JSON.parse(JSON.stringify(res.data.data));
          this.tableData = asas.map((item) => {
            this.director = [];
            this.head = [];
            item.leaders.forEach((ivos) => {
              this.director.push(ivos.dUname);
              this.head.push(ivos.dName);
            });
            return {
              ...item,
              director: this.director.join(" , "),
              head: this.head.join(" , "),
            };
          });
        }
      });
    },
    // 展开行
    clickTable(row,index,e){
           this.$refs.refTable.toggleRowExpansion(row)
      },
    // 每页几条
    handleSizeChange(val) {
      this.current = 1
      this.pageSize = val
       let from = {
        current:this.current,
        page:this.pageSize
        }
      this.getServiceList(from)
    },
    // 页数切换事件
    handleCurrentChange(val) {
      this.current = val
      let from = {
        current:this.current,
        page:this.pageSize,
        }
      this.getServiceList(from)

    },
  },
};
