import { request, requestFd } from '../core/request'

// 获取需求方列表
export function _getServiceTableList(params) {
  console.log('获取需求方列表')
  return request({
      url:'/ananops/api/v1/pri/bindsp/bindlist',
      method: 'post',
      data: params,
      showLoading: 'true',
      tokenStatus: true // 是否加token
  })
}
// 服务商名称下拉数据
export function _getscansp(params) {
  console.log('服务商名称下拉数据')
  return request({
      url:'/ananops/api/v1/pri/bindsp/scansp',
      method: 'post',
      data: params,
      showLoading: 'true',
      tokenStatus: true // 是否加token
  })
}
// 提交绑定服务商
export function _getbind(params) {
  console.log('提交绑定服务商')
  return request({
      url:'/ananops/api/v1/pri/bindsp/bind',
      method: 'post',
      data: params,
      showLoading: 'true',
      tokenStatus: true // 是否加token
  })
}

// 查询未绑定的部门
export function _getdebindment(params) {
  console.log('查询未绑定的部门')
  return request({
      url:`/ananops/api/v1/pri/bindsp/debindment?spdid=${params.spdid}`,
      method: 'post',
      data: params,
      showLoading: 'true',
      tokenStatus: true // 是否加token
  })
}

// 部门负责人信息查看 //服务商界面选择部门负责人是旧版使用
export function _getscandeptadmin(params) {
  console.log('部门负责人信息查看')
  return request({
      url:`/ananops/api/v1/pri/organ/scandeptadmin?did=${params.did}`,
      method: 'post',
      data: params,
      showLoading: 'true',
      tokenStatus: true // 是否加token
  })
}


// 部门负责人信息查看  //服务商界面选择部门负责人是新版shz使用
export function _getGetConfirms(params) {
  console.log('部门负责人信息查看')
  return request({
      url:`/ananops/api/v1/pri/report/getConfirms?did=${params.did}`,
      method: 'get',
      data: params,
      showLoading: 'true',
      tokenStatus: true // 是否加token
  })
}
// 部门负责人信息查看  //服务商界面选择部门负责人是新版shz使用  2022 0214
export function _getGetConfirmsq(params) {
  console.log('部门负责人信息查看')
  return request({
      url:`/ananops/api/v1/pri/report/getAllConfirms?did=${params.did}`,
      method: 'get',
      data: params,
      showLoading: 'true',
      tokenStatus: true // 是否加token
  })
}



// 过滤需求方已绑定的服务商
export function _getchecksp(params) {
  console.log('过滤需求方已绑定的服务商')
  return request({
      url:`/ananops/api/v1/pri/bindsp/checksp?did=${params.did}`,
      method: 'post',
      data: params,
      showLoading: 'true',
      tokenStatus: true // 是否加token
  })
}

// 服务方修改
export function _getupdatebind(params) {
  console.log('修改')
  return request({
      url:"/ananops/api/v1/pri/bindsp/updatebind",
      method: 'post',
      data: params,
      showLoading: 'true',
      tokenStatus: true // 是否加token
  })
}

// 需求方禁用绑定关系
export function _getdiableBind(params) {
  console.log('需求方禁用绑定关系')
  return request({
      url:"/ananops/api/v1/pri/bindsp/diableBind",
      method: 'post',
      data: params,
      showLoading: 'true',
      tokenStatus: true // 是否加token
  })
}


// 需求方启用绑定关系
export function _getenableBind(params) {
  console.log('需求方启用绑定关系')
  return request({
      url:"/ananops/api/v1/pri/bindsp/enableBind",
      method: 'post',
      data: params,
      showLoading: 'true',
      tokenStatus: true // 是否加token
  })
}


// 需求方续约
export function _getrenewal(params) {
  console.log('需求方续约')
  return request({
      url:"/ananops/api/v1/pri/bindsp/renewal",
      method: 'post',
      data: params,
      showLoading: 'true',
      tokenStatus: true // 是否加token
  })
}


// 需求方删除
export function _getdeleteBind(params) {
  console.log('需求方删除')
  return request({
      url:"/ananops/api/v1/pri/bindsp/deleteBind",
      method: 'post',
      data: params,
      showLoading: 'true',
      tokenStatus: true // 是否加token
  })
}
