//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  _getorderTypeLabel,
  _getorderList,
  _getmtOrderNumer,
  _checkexportMtorder,
} from '@/api/maintenance'
import Table from './table.vue'
import { _platMtOrderList } from '@/api/check'

export default {
  components: {
    Table,
  },
  data() {
    return {
      pageSize: 10, //没页几条
      current: 1, //当前页数
      total: 0, //总条数
      // editableTabsValue:"30",
      typesa: '',
      list: [],
      formInline: {
        orderId: '',
      },
      options: [
        {
          value: '选项1',
          label: '启用',
        },
        {
          value: '选项2',
          label: '禁用',
        },
      ],
      gdatList: [],
      tableData: [],
      activeName: '',
    }
  },
  created() {
    this.getorderinfo({
      current: 1,
      page: 10,
    })
    //  this.getorderinfo({ type:this.$route.query.selected?this.$route.query.selected:"30",pageSize:this.pageSize,current:this.current})
    //  this.getorderTypeLabel();
  },
  mounted() {
    // this.editableTabsValue = this.$route.query.selected
    //   ? this.$route.query.selected
    //   : "30";
  },
  methods: {
    //导出
    download() {
      _checkexportMtorder({}).then(res => {
        console.log(res)
        // const link = document.createElement('a')
        const blob = new Blob([res], { type: 'application/vnd.ms-excel' })

        const a = document.createElement('a')
        // 利用URL.createObjectURL()方法为a元素生成blob URL
        a.href = URL.createObjectURL(blob)
        // 设置文件名，目前只有Chrome和FireFox支持此属性
        // const filename = window.vm.$cookies.get('filename')
        const filename = '维保工单列表'
        a.download = filename
        a.click()
      })
      //  this.$refs.OrderTable.dialogTableVisible = true

      // this.$refs.OrderTable.init()

      // .then(etout => {
      //   console.log(etout)
      //   _this.platOrderList({ currentNumber: 1, pageSize: 10 })
      // })
      // let url ='http://130.10.7.48:8002/ananops/api/v1/pri/supervision/exportreport'
      // let link = document.createElement('a')
      // link.style.display = 'none'
      // link.href = url
      // link.id = 'Adownload'
      // link.setAttribute('download', 'excel.xls') //命名可能会出现问题，格式一定和后端下载的格式一样
      // document.body.appendChild(link)
      // link.click()
      // document.getElementById('Adownload').remove()
    },
    getorderinfo(from) {
      _platMtOrderList(from).then(res => {
        console.log(res)
        //  this.tableData = res.data.data;

        let tableData = res.data.data
        this.tableData = tableData.map(item => {
          return {
            ...item,
            createTimeFir: item.createTime.split(' ')[0],
            createTimeSec: item.createTime.split(' ')[1],
          }
        })
        this.total = res.data.total
        this.tableData.forEach(item => {
          item.progress = item.currentCount + '/' + item.count
        })
      })
    },
    // 获取标签状态
    getorderTypeLabel() {
      _getorderTypeLabel({}).then(res => {
        if (res.code == '1') {
          this.getmtOrderNumer()
          this.gdatList = res.data
        }
      })
    },
    // 小红点的数据
    getmtOrderNumer() {
      _getmtOrderNumer({}).then(res => {
        let aaa = res.data
        if (aaa.length > 0) {
          this.list = this.gdatList.reduce((pre, cur) => {
            let target = pre.find(ee => ee.orderStatus == cur.id)
            if (target) {
              Object.assign(target, cur)
            } else {
              pre.push(cur)
            }
            return pre
          }, aaa)
        } else {
          this.list = this.gdatList
        }
      })
    },
    query() {
      this.current = 1
      let from = {
        current: this.current,
        page: this.pageSize,
        // type:this.editableTabsValue,
        ...this.formInline,
      }
      this.getorderinfo(from)
    },

    // 标签的切换状态
    handleClick(tab) {
      let from = { type: tab.name }
      this.getorderinfo(from)
    },
    //更改每页几个
    handleSizeChange(val) {
      this.current = 1
      this.pageSize = val
      let from = {
        current: this.current,
        page: this.pageSize,
        ...this.formInline,
        // type:this.editableTabsValue
      }
      this.getorderinfo(from)
    },
    //更改页数
    handleCurrentChange(val) {
      this.current = val
      let from = {
        current: this.current,
        page: this.pageSize,
        ...this.formInline,
        // type:this.editableTabsValue
      }
      this.getorderinfo(from)
    },
  },
}
