var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-dialog',{attrs:{"title":_vm.title,"visible":_vm.dialogVisible,"width":"33%"},on:{"update:visible":function($event){_vm.dialogVisible=$event},"close":_vm.close}},[_c('el-form',{ref:"form",attrs:{"model":_vm.form,"label-width":"100px"}},[_c('el-row',[_c('el-form-item',{attrs:{"prop":"spdid","rules":{
          required: true,
          message: '请选择服务商',
          trigger: 'change',
        },"label":"服务商名称"}},[_c('el-select',{attrs:{"disabled":_vm.viewBtn || _vm.serveBtn || _vm.contract,"filterable":"","remote":"","placeholder":"请输入内容","remote-method":_vm.remoteMethod},on:{"change":_vm.changeMet},model:{value:(_vm.form.spdid),callback:function ($$v) {_vm.$set(_vm.form, "spdid", $$v)},expression:"form.spdid"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.did,attrs:{"label":item.name,"value":item.did}})}),1)],1)],1),_c('el-row',[_c('el-form-item',{attrs:{"label":"需求方部门"}},[(!_vm.viewBtn && !_vm.contract)?_c('div',{staticClass:"adds",on:{"click":_vm.changeCount}},[_c('div',{staticClass:"addbtn",staticStyle:{"cursor":"pointer"}},[_c('img',{attrs:{"src":require("@/assets/img/add.png"),"alt":""}}),_c('p',{staticClass:"add"},[_vm._v("添加")])])]):_vm._e()])],1),_vm._l((_vm.form.checkedlists),function(item,index){return _c('el-row',{key:index,staticStyle:{"display":"flex","justify-content":"left"}},[_c('el-form-item',{attrs:{"prop":("checkedlists[" + index + "].leadersddId"),"rules":{
          required: true,
          message: '请选择部门',
          trigger: 'change',
        },"label-width":"100px"}},[_c('el-select',{attrs:{"disabled":(_vm.viewBtn && _vm.serveBtn) || _vm.contract,"clearable":"","placeholder":"请选择单位"},on:{"change":function($event){return _vm.changebm(item.leadersddId, index)}},model:{value:(item.leadersddId),callback:function ($$v) {_vm.$set(item, "leadersddId", $$v)},expression:"item.leadersddId"}},_vm._l((_vm.curcityist),function(item){return _c('el-option',{key:item.id,attrs:{"label":item.name,"value":item.id,"disabled":item.disabled}})}),1)],1),_c('el-form-item',{attrs:{"prop":("checkedlists[" + index + "].leadersId"),"rules":{
          required: true,
          message: '请选择负责人',
          trigger: 'change',
        },"label-width":"10px"}},[_c('el-select',{staticStyle:{"position":"relative"},attrs:{"clearable":"","disabled":(_vm.viewBtn && _vm.serveBtn) || _vm.contract,"placeholder":"请选择负责人"},model:{value:(item.leadersId),callback:function ($$v) {_vm.$set(item, "leadersId", $$v)},expression:"item.leadersId"}},_vm._l((item.uesrlist),function(val){return _c('el-option',{key:val.id,attrs:{"label":val.name,"value":val.id}})}),1),(!_vm.viewBtn && !_vm.contract)?_c('img',{staticStyle:{"position":"absolute","right":"-20px","margin-top":"10px"},attrs:{"src":require('@/assets/img/ico_gb (1).png'),"alt":""},on:{"click":function($event){return _vm.dellist(index)}}}):_vm._e()],1)],1)}),_c('el-row',[_c('el-form-item',{attrs:{"label":"结算方式","prop":"payType","rules":{
          required: true,
          message: '请选择结算方式',
          trigger: 'change',
        }}},[_c('el-select',{attrs:{"clearable":"","disabled":(_vm.viewBtn && _vm.serveBtn) || _vm.contract,"placeholder":"请选择结算方式"},model:{value:(_vm.form.payType),callback:function ($$v) {_vm.$set(_vm.form, "payType", $$v)},expression:"form.payType"}},_vm._l((_vm.paymentList),function(val){return _c('el-option',{key:val.id,attrs:{"label":val.name,"value":val.id}})}),1)],1)],1),_c('el-row',[_c('el-form-item',{attrs:{"label":"开始日期","prop":"startTime","rules":{
          required: true,
          message: '请选择开始日期',
          trigger: 'change',
        }}},[_c('el-date-picker',{attrs:{"disabled":(_vm.viewBtn && _vm.serveBtn) || _vm.contract,"placeholder":"开始日期","value-format":"yyyy-MM-ddTHH:mm:SS.000Z","picker-options":_vm.startPickerOptions},model:{value:(_vm.form.startTime),callback:function ($$v) {_vm.$set(_vm.form, "startTime", $$v)},expression:"form.startTime"}})],1)],1),_c('el-row',[_c('el-form-item',{attrs:{"label":"结束日期","prop":"endTime","rules":{
          required: true,
          message: '请选择结束日期',
          trigger: 'change',
        }}},[_c('el-date-picker',{attrs:{"disabled":_vm.viewBtn && _vm.serveBtn && (_vm.contract || _vm.viewBtn),"value-format":"yyyy-MM-ddTHH:mm:SS.000Z","placeholder":"结束日期","picker-options":_vm.pickerOptions},model:{value:(_vm.form.endTime),callback:function ($$v) {_vm.$set(_vm.form, "endTime", $$v)},expression:"form.endTime"}})],1)],1),_c('el-form-item',{attrs:{"label":"备注"}},[_c('el-input',{staticClass:"textarea",attrs:{"disabled":(_vm.viewBtn && _vm.serveBtn) || _vm.contract,"type":"textarea","placeholder":"请输入","maxlength":"200","show-word-limit":"","resize":"none","size":"medium"},model:{value:(_vm.form.opComment),callback:function ($$v) {_vm.$set(_vm.form, "opComment", $$v)},expression:"form.opComment"}})],1)],2),_c('span',[_c('el-button',{attrs:{"type":"primary","disabled":_vm.viewBtn},on:{"click":_vm.save}},[_vm._v("提交绑定")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }