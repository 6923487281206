//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as echarts from 'echarts'
import { _getservic } from '@/api/Regulatory.js'

export default {
  data() {
    return {
      formInline: {
        startTime: '',
        endTime: '',
      },
      time: [],
      data: [],
      legend: [],
    }
  },
  created(){
    this.worksList(this.formInline)
  },
  methods: {
    query() {
      this.worksList(this.formInline)
    },
   drawLine() {
      // 基于准备好的dom，初始化echarts实例
      let chartDom = document.getElementById("myCharts");
      let myChart = echarts.init(chartDom);
      // 绘制图表
      myChart.setOption({
        tooltip: {
          //提示性文本消息
          trigger: "axis",
          axisPointer: {
            label: {
              show: true,
              backgroundColor: "#fff",
              color: "#556677",
              borderColor: "rgba(0,0,0,0)",
              shadowColor: "rgba(0,0,0,0)",
              shadowOffsetY: 0,
            },
            lineStyle: {
              width: 0,
            },
          },
          backgroundColor: "#fff",
          textStyle: {
            color: "#5c6c7c",
          },
          padding: [10, 10],
          extraCssText: "box-shadow: 1px 0 2px 0 rgba(163,163,163,0.5)",
        },
        legend: {
          y: "bottom",
          data: this.legend,
          textStyle: {
            fontSize: 14,
          },
        },
        grid: {
          //图表位置
          top: "3%",
          left: "3%",
          right: "4%",
          bottom: "10%",
          containLabel: true,
        },
        xAxis: [
          //横坐标
          {
            type: "category",
            boundaryGap: false,
            data: this.time,
          },
        ],
        yAxis: [
          {
            // 纵轴标尺固定
            type: "value",
            scale: true,
            splitNumber: 7,
          },
        ],
        series: this.data,
      });
    },
    // 获取折现图数据
    worksList(from) {
      this.time = [];
      this.legend = [];
      this.data = [];
      _getservic(from).then(res => {
       //X轴数据
        this.time = res.data.workOrder.map((item) => {
          return item.month;
        });
        //聚合下面的文字
        res.data.workOrder.map((item) => {
          this.legend = item.data.map((iovs) => {
            return iovs.name;
          });
        });
        //聚合data大数组
        res.data.workOrder.map((item) => {
          this.data = [];
          item.data.forEach((iovs, index) => {
            this.data.push({
              name: iovs.name,
              type: "line",
              smooth: true,
              showSymbol: true,
              symbolSize: 8,
              zlevel: 3,
              data: [],
            });
          });
        });
        this.data.forEach((item)=>{
          res.data.workOrder.forEach((iovs)=>{
            iovs.data.forEach((desp)=>{
              if(item.name == desp.name){
                item.data.push(desp.value)
              }
            })
          })
        })
        this.drawLine();
      })
    },
  },
}
