//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AddService from './AddService.vue'
import Contract from './contract.vue'
import { _getcode } from '@/api/organizational'
import {
  _getServiceTableList,
  _getdiableBind,
  _getdeleteBind,
  _getenableBind,
} from '@/api/service.js'
export default {
  data() {
    return {
      pageSize: 10, //没页几条
      current: 1, //当前页数
      total: 0, //总条数
      aaa: [],
      bbb: [],
      formInline: {
        spConfimStatus: '', //搜索条件
      },
      options: [], //计划状态  搜索的下拉
      tableData: [], //表格数据
      roleType: '',
    }
  },
  created() {
    // 表格数据
    this.getServiceList({ current: this.current, page: this.pageSize }) //初始页面请求第一页
    this.roleType = JSON.parse(localStorage.getItem('roleType')) //获取用户角色类型
    this.getcode()
  },
  components: {
    AddService,
    Contract,
  },
  methods: {
    query() {
      this.getServiceList({ current: this.current, page: this.pageSize }) //初始页面请求第一页
    },
    tableHeaderColor({ row, rowIndex, column, columnIndex }) {
      if (rowIndex === 0) {
        return 'background-color:#e8f4ff;font-weight: 400;border-radius: 4px;color: #5f8bcf;height:60px;font-size:15px;font-family: Microsoft YaHei, Microsoft YaHei-Regular;padding:5px 0'
      }
    },
    //计划状态的下拉数据
    getcode() {
      let from = {
        para: 'bdzt',
      }
      _getcode(from).then(res => {
        this.options = res.data
      })
    },
    //点击修改
    handleClick(row) {
      this.$refs.add.changeDiaLog('修改', row, '2')
    },
    //点击新增
    addService() {
      this.$refs.add.changeDiaLog('新增')
    },
    // 禁用事件
    switchbtn(row) {
      this.$confirm('是否禁用', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        _getdiableBind({ id: row.id }).then(res => {
          if (res.code == '1') {
            this.$message(res.msg)
            this.getServiceList({ current: this.current, page: this.pageSize })
          }
        })
      })
    },
    // 启用事件
    enablebtn(row) {
      this.$confirm('是否启用', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        _getenableBind({ id: row.id }).then(res => {
          if (res.code == '1') {
            this.$message(res.msg)
            this.getServiceList({ current: this.current, page: this.pageSize })
          }
        })
      })
    },
    // 续约事件
    contractBtn(row) {
      this.$refs.contract.changeDiaLog('续约', row)
    },
    // 删除事件
    delBtn(row) {
      this.$confirm('是否删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(() => {
        _getdeleteBind({ id: row.id }).then(res => {
          console.log(res)
          // if (res.code == 1) {
            this.$message(res.msg)
          // }


          this.getServiceList({ current: this.current, page: this.pageSize })
        })
      })
    },
    //点击查询
    searchList() {
      this.getServiceList(this.formInline)
    },
    //获取表格中的数据
    getServiceList(form) {
      _getServiceTableList(form).then(res => {
        this.total = res.data.total
        this.tableData = res.data.data.map(item => {
          this.aaa = []
          this.bbb = []
          item.leaders.map(ivos => {
            this.aaa.push(ivos.spleaderlName)
            this.bbb.push(ivos.spdName)
          })
          return {
            ...item,
            director: [...new Set(this.aaa)].join(' , '),
            head: [...new Set(this.bbb)].join(' , '),
          }
        })
      })
    },
    // 展开行
    clickTable(row, index, e) {
      this.$refs.refTable.toggleRowExpansion(row)
    },
    // 切换每页几条的事件
    handleSizeChange(val) {
      this.current = 1
      this.pageSize = val
      let from = {
        current: this.current,
        page: this.pageSize,
      }
      this.getServiceList(from)
    },
    // 切换页数的事件
    handleCurrentChange(val) {
      this.current = val
      let from = {
        current: this.current,
        page: this.pageSize,
      }
      this.getServiceList(from)
    },
  },
}
