//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _getUserList, _gettree, _getcode } from '@/api/organizational.js'
import {
  _getscansp,
  _getbind,
  _getdebindment,
  _getscandeptadmin,
  _getchecksp,
  _getupdatebind,
  _getrenewal,
  _getGetConfirms,
  _getGetConfirmsq
} from '@/api/service.js'

// let day = 7;
export default {
  data() {
    return {
      // 结束日期不能大于开始日期
      pickerOptions: {
        disabledDate: time => {
          let starTime = this.form.startTime
          return time.getTime() < new Date(starTime).getTime()
        },
      },
      // 开始日期不能小于结束日期
      startPickerOptions: {
        disabledDate: time => {
          let endTime = this.form.endTime
          return time.getTime() > new Date(endTime).getTime()
        },
      },
      disab: false,
      title: '', //标题
      dialogVisible: false, //控制弹窗显隐
      curcityist: [], //单位列表
      options: [], //服务商列表
      paymentList: [],
      //form表单数据
      form: {
        spdid: '', //服务商id
        name: '',
        id: '',
        checkedlists: [],
        opComment: '',
        startTime: '',
        endTime: '',
      },
      viewBtn: false, //查看进来禁用
      serveBtn: false, //服务商的禁用
      contract: false, //续约的禁用
    }
  },
  methods: {
    //显示隐藏弹窗
    changeDiaLog(title, params, type) {
      if (this.$refs['form']) {
        this.$refs['form'].resetFields()
      }
      this.dialogVisible = true
      this.getcode()
      // 查看
      if (type == '1') {
        this.title = title
        let that = this
        // 获取服务商列表
        this.remoteMethod(params.spDname)
        _gettree({ id: '', child: false }).then(res => {
          this.curcityist = JSON.parse(JSON.stringify(res.data))
        })
        that.form.checkedlists = []
        params.leaders.forEach((item, index) => {
          that.form.checkedlists.push({
            leadersddId: '',
            leadersId: '',
            uesrlist: [], //负责人列表
          })
          that.form.checkedlists[index].leadersddId = item.dDid
          that.form.checkedlists[index].leadersId = item.dUid
          console.log(11111111111111)
          _getscandeptadmin({ did: item.dDid }).then(res => {
            that.form.checkedlists[index].uesrlist = res.data
            this.$forceUpdate()
          })
        })
        this.form.spdid = params.spDid //服务商的spDid
        this.form.payType = params.payType
        ;(this.form.startTime = params.startTime + '.000Z'), //开始时间
          (this.form.endTime = params.endTime + '.000Z'), //结合时间
          (this.viewBtn = true)
        this.serveBtn = true
        this.contract = false
      } else if (type == '2') {
        //修改
        this.title = title
        let that = this
        // 获取服务商列表
        this.remoteMethod(params.spDname)
        _gettree({ id: '', child: false }).then(res => {
          this.curcityist = JSON.parse(JSON.stringify(res.data))
          params.leaders.forEach(item => {
            this.curcityist.forEach(iovs => {
              if (item.dDid == iovs.id) {
                iovs.disabled = true
              }
            })
          })
        })
        that.form.checkedlists = []
        params.leaders.forEach((item, index) => {
          that.form.checkedlists.push({
            leadersddId: '',
            leadersId: '',
            uesrlist: [], //负责人列表
          })
          that.form.checkedlists[index].leadersddId = item.dDid
          that.form.checkedlists[index].leadersId = item.dUid
          console.log(11111111111111)
          _getGetConfirmsq({ did: item.dDid  }).then(res => {
            that.form.checkedlists[index].uesrlist = res.data
            this.$forceUpdate()
          })
          // _getscandeptadmin({ did: item.dDid }).then(res => {
          //   that.form.checkedlists[index].uesrlist = res.data
          //   this.$forceUpdate()
          // })
        })
        this.form.spdid = params.spDid //服务商的spDid
        this.form.id = params.id //数据行id
        this.form.payType = params.payType
        // let aaa = params.starTime
        ;(this.form.startTime = params.startTime + '.000Z'), //开始时间
          (this.form.endTime = params.endTime + '.000Z'), //结合时间
          (this.viewBtn = false)
        this.serveBtn = true
        this.contract = false
      } else if (type == '3') {
        //续约
        this.form.id = params.id //数据行id
        this.title = title
        let that = this
        // 获取服务商列表
        this.remoteMethod(params.spDname)
        _gettree({ id: '', child: false }).then(res => {
          this.curcityist = JSON.parse(JSON.stringify(res.data))
        })
        that.form.checkedlists = []
        params.leaders.forEach((item, index) => {
          that.form.checkedlists.push({
            leadersddId: '',
            leadersId: '',
            uesrlist: [], //负责人列表
          })
          that.form.checkedlists[index].leadersddId = item.dDid
          that.form.checkedlists[index].leadersId = item.dUid
          console.log(11111111111111)
          _getscandeptadmin({ did: item.dDid }).then(res => {
            that.form.checkedlists[index].uesrlist = res.data
            this.$forceUpdate()
          })
        })
        this.form.spdid = params.spDid //服务商的spDid
        this.form.payType = params.payType
        ;(this.form.startTime = params.startTime + '.000Z'), //开始时间
          (this.form.endTime = params.endTime + '.000Z'), //结合时间
          (this.viewBtn = false)
        this.serveBtn = true
        this.contract = true
      } else {
        //新增
        this.title = title
        this.form = {
          name: '',
          checkedlists: [],
          startTime: '',
          endTime: '',
          opComment: '',
        }
      }
    },
    // 服务商名称的筛选事件
    remoteMethod(query) {
      this.form.name = query
      _getscansp({ spName: this.form.name }).then(res => {
        this.options = res.data
      })
    },
    // 服务商的事件
    changeMet(val) {
      this.getee({ spdid: val })
      this.getchecksp({ did: val })
    },
    // 过滤需求方已绑定的服务商
    getchecksp(from) {
      _getchecksp(from).then(res => {
        if (res.code == '1') {
          this.disab = false
        } else {
          this.disab = true
        }
      })
    },
    // 获取对应的部门
    getee(data) {
      _getdebindment(data).then(res => {
        this.curcityist = JSON.parse(JSON.stringify(res.data))
        this.curcityist.forEach(item => {
          item.disabled = false
        })
      })
    },
    // 结算方式的下拉列表
    getcode() {
      let from = {
        para: 'jsfs',
      }
      _getcode(from).then(res => {
        this.paymentList = res.data
      })
    },

    //点击添加
    changeCount() {
      this.form.checkedlists.push({
        leadersddId: '',
        leadersId: '',
        uesrlist: [],
      })
    },
    //点击删除所选的
    dellist(i) {
      this.curcityist.filter(item => {
        if (item.id == this.form.checkedlists[i].leadersddId) {
          item.disabled = false
        }
      })
      this.form.checkedlists.splice(i, 1)
    },
    //切换部门事件
    changebm(id, i) {
      this.form.checkedlists[i].leadersId = ''
      this.curcityist.filter(item => {
        if (item.id == id) {
          item.disabled = true
        }
      })
      let aaa = []
      this.form.checkedlists.forEach(item => {
        aaa.push(item)
      })
      let add = this.curcityist.filter(
        item => !aaa.some(ele => ele.leadersddId === item.id),
      )
      add.forEach(item => {
        item.disabled = false
      })
      console.log(11111111111111)
      _getGetConfirmsq({ did: id }).then(res => {
        this.form.checkedlists[i].uesrlist = JSON.parse(
          JSON.stringify(res.data),
        )
      })
      // _getscandeptadmin({ did: id }).then(res => {
      //   this.form.checkedlists[i].uesrlist = JSON.parse(
      //     JSON.stringify(res.data),
      //   )
      // })
    },
    // 所有的部门数据
    gettree() {
      _gettree({ id: '', child: false }).then(res => {})
    },
    // 确定事件
    save() {
      if (this.form.checkedlists.length == '0') {
        this.$message('至少选择一个部门')
      } else {
        this.$refs['form'].validate(valid => {
          if (valid) {
            this.viewBtn = true
            if (this.title == '新增') {
              let from = {
                child: this.form.checkedlists,
                spDid: this.form.spdid,
                startTime: this.form.startTime,
                endTime: this.form.endTime,
                payType: this.form.payType,
                id: this.form.id ? this.form.id : '',
              }
              _getbind(from)
                .then(res => {
                  if (res.code == '1') {
                    this.viewBtn = false
                    this.$emit('query')
                    this.dialogVisible = false
                  } else {
                    this.viewBtn = false
                  }
                })
                .catch(() => {
                  this.viewBtn = false
                })
            } else if (this.title == '修改') {
              let from = {
                child: this.form.checkedlists,
                spDid: this.form.spdid,
                startTime: this.form.startTime,
                endTime: this.form.endTime,
                payType: this.form.payType,
                id: this.form.id ? this.form.id : '',
              }
              _getupdatebind(from)
                .then(res => {
                  if (res.code == '1') {
                    this.viewBtn = false
                    this.$emit('query')
                    this.dialogVisible = false
                  } else {
                    this.viewBtn = false
                  }
                })
                .catch(() => {
                  this.viewBtn = false
                })
            } else if (this.title == '续约') {
              let asfd = {
                id: this.form.id,
                endTime: this.form.endTime,
              }
              _getrenewal(asfd)
                .then(res => {
                  if (res.code == '1') {
                    this.$emit('query')
                    this.dialogVisible = false
                    this.viewBtn = false
                  } else {
                    this.viewBtn = false
                  }
                })
                .catch(() => {
                  this.viewBtn = false
                })
            }
          }
        })
      }
    },
    // 弹窗关闭的回调
    close() {
      this.title = ''
      ;(this.form = {
        spdid: '', //服务商id
        name: '',
        id: '',
        payType: '',
        checkedlists: [],
        opComment: '',
        startTime: '',
        endTime: '',
      }),
        (this.options = [])
      this.disab = false
      this.viewBtn = false
      this.serveBtn = false
      this.contract = false
      this.$refs['form'].resetFields()
    },
  },
}
