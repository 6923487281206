//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  _confirm,
  _reject,
  _scandeptadmin,
  _updateConfirm,
  _getGetConfirms
} from "@/api/demandside.js";
import { _gettree, _getUserList } from "@/api/organizational.js";

export default {
  watch: {
    "form.spConfimStatus"(val) {
      this.swith = val == "1" ? true : false;
      this.$forceUpdate();
    },
  },
  data() {
    return {
      swith: true,
      title: "", //弹框名称
      dialogVisible: false,
      dDid: "", //父组件传过来的数据id
      id: "",
      curcityist: [], //部门数据
      asas: [],
      viewBtn: false,
      gitBtn:"",
      types:"",
      form: {
        spConfimStatus: "",
        checkedlists: [
          {
            ddDid:"",
            dName: "",
            spDid: "",
            spLeaderId: "",
            uesrlist: [], //主管数据
          },
        ],
        opComment: "",
        reason: "",
      },
    };
  },
  methods: {
    // 接受父组件
    changeDiaLog(title, params,type){
      this.gettree({ child: false });
      this.dialogVisible = true;
      this.title = title
      this.dDid = params.dDid; //修改的数据id
      this.id = params.id;
      this.form.spConfimStatus = params.spConfimStatus == 0 ? 1 :params.spConfimStatus;//是否绑定的回显
      this.form.opComment = params.opComment;//备注回显
      this.form.reason = params.reason;//原因回显
      this.types = type
      if(type == "1"){//1是查看
         this.form.checkedlists = []
        params.leaders.forEach((item,index)=>{
          this.form.checkedlists.push({
            dName: item.dName,
            ddDid:item.dDid,
            spDid: "",
            spLeaderId: "",
            uesrlist: [], //主管数据
          })
          this.form.checkedlists[index].spDid = item.spdDid;
          this.form.checkedlists[index].spLeaderId = item.spleaderId;
           _scandeptadmin({ did: item.spdDid }).then((res) => {
              this.form.checkedlists[index].uesrlist = res.data;
              this.$forceUpdate()
           })
           this.viewBtn = true;
           this.gitBtn = true
        })
      }else if(type == "2"){//2是修改
         this.form.checkedlists = []
        params.leaders.forEach((item,index)=>{
           this.form.checkedlists.push({
            dName: item.dName,
            ddDid:item.dDid,
            spDid: "",
            spLeaderId: "",
            uesrlist: [], //主管数据
          })
          this.form.checkedlists[index].spDid = item.spdDid;
          this.form.checkedlists[index].spLeaderId = item.spleaderId;
           _scandeptadmin({ did: item.spdDid }).then((res) => {
              this.form.checkedlists[index].uesrlist = res.data;
              this.$forceUpdate()
           })
           this.viewBtn = false;
           this.gitBtn = true
        })
      }else{//3是绑定
      this.form.checkedlists = []
        params.leaders.forEach((item,index)=>{
           this.form.checkedlists.push({
            dName: item.dName,
            ddDid:item.dDid,
            spDid: "",
            spLeaderId: "",
            uesrlist: [], //主管数据
          })
        })
        this.viewBtn = false;
        this.gitBtn = false
      }
    },
    // 获取部门数据
    gettree(from) {
      
      _gettree(from).then((res) => {
        this.curcityist = JSON.parse(JSON.stringify(res.data));
      });
    },
    //切换部门事件
    changebm(id, i) {
      _getGetConfirms({ did: id }).then((res) => {
        this.$forceUpdate();
        this.form.checkedlists[i].spLeaderId = "";
        this.form.checkedlists[i].uesrlist = JSON.parse(
          JSON.stringify(res.data)
        );
      });
      // _scandeptadmin({ did: id }).then((res) => {
      //   this.$forceUpdate();
      //   this.form.checkedlists[i].spLeaderId = "";
      //   this.form.checkedlists[i].uesrlist = JSON.parse(
      //     JSON.stringify(res.data)
      //   );
      // });
    },
    // 提交绑定
    save() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.viewBtn = true
          if(this.types == "2" && this.form.spConfimStatus=="1"){//修改界面和确定提交绑定
            let iovs = {
            id: this.id,
            spConfimStatus: this.form.spConfimStatus, //是否绑定
            opComment: this.form.opComment, //备注
             opList: this.form.checkedlists,
          }
          _updateConfirm(iovs).then((res)=>{
            if(res.code == "1"){
              this.viewBtn = false 
              this.$message(res.msg);
             this.$emit("query");
             this.dialogVisible = false;
            }else{
              this.viewBtn = false 
            }
            
          }).catch(()=>{
            this.viewBtn = false 
          })
          }else if(this.types == "2" && this.form.spConfimStatus=="2"){//修改界面和拒绝提交绑定
            let iovss = {
            id: this.id,
            spConfimStatus: this.form.spConfimStatus, //是否绑定
            failReason: this.form.failReason, //拒绝原因
          }
          _reject(iovss).then((res)=>{
            if(res.code == "1"){
               this.$message(res.msg);
               this.viewBtn = false 
             this.$emit("query");
             this.dialogVisible = false;
            }else{
              this.viewBtn = false 
            }
            
          }).cahch(()=>{
            this.viewBtn = false 
          })
          }else if(this.types == "3" && this.form.spConfimStatus=="1"){//绑定界面和同意提交绑定
           let iovs = {
            id: this.id,
            spConfimStatus: this.form.spConfimStatus, //是否绑定
            opComment: this.form.opComment, //备注
             opList: this.form.checkedlists,
          }
            _confirm(iovs).then((res)=>{
              if(res.code == "1"){
                this.viewBtn = false 
                 this.$message(res.msg);
             this.$emit("query");
             this.dialogVisible = false;
              }else{
                this.viewBtn = false 
              }
             
            }).catch(()=>{
              this.viewBtn = false 
            })
          }else if(this.types == "3" && this.form.spConfimStatus=="2"){//绑定界面和拒绝提交绑定
           let iovss = {
            id: this.id,
            spConfimStatus: this.form.spConfimStatus, //是否绑定
            failReason: this.form.failReason, //拒绝原因
          }
            _reject(iovss).then((res)=>{
              if(res.code == "1"){
                this.viewBtn = false 
                this.$message(res.msg);
             this.$emit("query");
             this.dialogVisible = false;
              }else{
               this.viewBtn = false 
              }
            }).catch(()=>{
             this.viewBtn = false 
            })
          }
        }
      })
     
    },
    // 关闭弹框事件
    close() {
      this.viewBtn = false;
      this.$refs["form"].resetFields();
    },
  },
};
