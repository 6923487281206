//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _getPerfectList, _disable, _enable } from "@/api/auditPtf.js";
export default {
  data() {
    return {
      pageSize: 10, //没页几条
      current: 1, //当前页数
      total: 0, //总条数
      formInline: {
        orderId: "",
        eaStatusCode: "",
      },
      options: [
        {
          value: "选项1",
          label: "启用",
        },
        {
          value: "选项2",
          label: "禁用",
        },
      ],
      value: "",
      input: "",
      tableData: [],
    };
  },
  created() {
    this.getPerfectList({
      current: this.current,
      page: this.pageSize,
      type: "2",
      name: "",
    });
  },
  methods: {
    tableHeaderColor({ row, rowIndex, column, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color:#e8f4ff;font-weight: 400;border-radius: 4px;color: #5f8bcf;height:60px;font-size:15px;font-family: Microsoft YaHei, Microsoft YaHei-Regular;padding:5px 0";
      }
    },
    // 查询
    query() {
      this.getPerfectList({
        current: this.current,
        page: this.pageSize,
        type: "2",
        name: this.formInline.name,
      });
    },
    // 列表数据接口
    getPerfectList(from) {
      _getPerfectList(from).then((res) => {
        res.data.data.map((item) => {
          return (item.disableStatusName =
            item.disableStatus == "0" ? "禁用" : item.disableStatus == "1" ? "启用" : "");
        });
        this.tableData = res.data.data;
        this.total = res.data.total;
      });
    },
    // 点击表格数据跳转
    tabClick(row) {
      this.$router.push({
        path: "/main/enterprise/serviceManagement/info",
        query: { id: row.id, swith: "2" },
      });
    },
    // 启用
    enablebtn(row) {
      this.$confirm("是否确定启用", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        _enable({ id: row.id }).then((res) => {
          if (res.code == "1") {
            this.$message(res.msg);
            this.getPerfectList({
              current: this.current,
              page: this.pageSize,
              type: "2",
              name: "",
            });
          }
        });
      });
    },
    // 禁用
    disabledsbtn(row) {
      this.$confirm("是否确定禁用", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        _disable({ id: row.id }).then((res) => {
          if (res.code == "1") {
            this.$message(res.msg);
            this.getPerfectList({
              current: this.current,
              page: this.pageSize,
              type: "2",
              name: "",
            });
          }
        });
      });
    },
    // 分页
    handleSizeChange(val) {
      this.current = 1;
      this.pageSize = val;
      let from = {
        current: this.current,
        page: this.pageSize,
        type: "2",
      };
      this.getPerfectList(from);
    },
    handleCurrentChange(val) {
      this.current = val;
      let from = {
        current: this.current,
        page: this.pageSize,
        type: "2",
      };
      this.getPerfectList(from);
    },
  },
};
