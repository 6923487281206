/*
 * @Author: your name
 * @Date: 2022-01-07 16:16:35
 * @LastEditTime: 2022-01-18 15:47:32
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \ananyuweiback\src\api\demandside.js
 */
import { request, requestFd } from '../core/request'

// 获取服务商列表
export function _getDemandSideTableList(params) {
  console.log('获取服务商列表')
  return request({
      url:'/ananops/api/v1/pri/bindsp/spbindlist',
      method: 'post',
      data: params,
      showLoading: 'true',
      tokenStatus: true // 是否加token
  })
}
// 绑定服务商
export function _confirm(params) {
  console.log('绑定服务商')
  return request({
      url:'/ananops/api/v1/pri/bindsp/confirm',
      method: 'post',
      data: params,
      showLoading: 'true',
      tokenStatus: true // 是否加token
  })
}
// 拒绝绑定服务商
export function _reject(params) {
  console.log('拒绝绑定服务商')
  return request({
      url:'/ananops/api/v1/pri/bindsp/reject',
      method: 'post',
      data: params,
      showLoading: 'true',
      tokenStatus: true // 是否加token
  })
}


// 服务商修改绑定
export function _updateConfirm(params) {
  console.log('拒绝绑定服务商')
  return request({
      url:'/ananops/api/v1/pri/bindsp/updateConfirm',
      method: 'post',
      data: params,
      showLoading: 'true',
      tokenStatus: true // 是否加token
  })
}

// 部门负责人下拉
export function _scandeptadmin(params) {
  console.log('部门负责人下拉')
  return request({
      url:`/ananops/api/v1/pri/organ/scandeptadmin?did=${params.did}`,
      method: 'post',
      data: params,
      showLoading: 'true',
      tokenStatus: true // 是否加token
  })
}


// 部门负责人信息查看  //服务商界面选择部门负责人是新版shz使用
export function _getGetConfirms(params) {
  console.log('部门负责人信息查看')
  return request({
      url:`/ananops/api/v1/pri/report/getConfirms?did=${params.did}`,
      method: 'get',
      data: params,
      showLoading: 'true',
      tokenStatus: true // 是否加token
  })
}