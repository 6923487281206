//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {_getUnPerfectList} from "@/api/auditPtf.js"
export default {
  data() {
    return {
      pageSize:10,//没页几条
      current: 1,//当前页数
      total:0,//总条数
      formInline: {
        name: "",
        eaStatusCode: "",
        current:this.current,
        page:this.pageSize
      },
      options: [
        {
          value: "选项1",
          label: "启用",
        },
        {
          value: "选项2",
          label: "禁用",
        },
      ],
      value: "",
      input: "",
      tableData: [],
    };
  },
  created(){
      this.getUnPerfectList({current:this.current, page:this.pageSize})
  },
  methods: {
    tableHeaderColor({ row, rowIndex, column, columnIndex }) {
      if (rowIndex === 0) {
        return "background-color:#e8f4ff;font-weight: 400;border-radius: 4px;color: #5f8bcf;height:60px;font-size:15px;font-family: Microsoft YaHei, Microsoft YaHei-Regular;padding:5px 0";
      }
    },
    // 查询
    query(){
      this.getUnPerfectList({name:this.formInline.name,current:this.current, page:this.pageSize})
    },
    // 列表数据接口
    getUnPerfectList(from){
      _getUnPerfectList(from).then((res)=>{
        this.tableData = res.data.data
        this.total = res.data.total
      })
    },
    // 点击表格数据跳转
    tabClick(row) {
      this.$router.push({
        path: "/main/platform/platforms/mainplatforms",
        query: { id: row.id,swith:'1'},
      });
    },
    // 分页
    handleSizeChange(val) {
      this.current = 1
      this.pageSize = val
       let from = {
        current:this.current,
        page:this.pageSize
        }
      this.getUnPerfectList(from)
    },
    handleCurrentChange(val) {
       this.current = val
      let from = {
        current:this.current,
        page:this.pageSize,
        }
      this.getUnPerfectList(from)
    },
  },
};
