//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {_confirm} from "@/api/auditPtf"
export default {
  data() {
    return {
      dialogVisible: false,
      btnDisab:false,
      from : {
          id:"",
          failReason:"",
          checkStatus:0,
      }
    };
  },
  methods: {
    init(id) {
      this.from.id = id;
      this.dialogVisible = true;
    },
    // 确认
    adduser() {
      this.btnDisab = true
    _confirm(this.from).then((res)=>{
        if(res.code == "1"){
         this.$message(res.msg)
         this.btnDisab = false
         this.$router.push({
          path: "/main/platform/platforms",
         }); 
        }else{
          this.btnDisab = false
        }
      }).catch(()=>{
        this.btnDisab = false
      })
    },
    close() {
     this.from = {
          id:"",
          failReason:"",
          checkStatus:0,
      }
    },
  },
};
