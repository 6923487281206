//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { _getcode } from '@/api/organizational'
import {_confirm} from "@/api/auditPtf"

//获取省市区三级联动数据接口
import {
  _getProvinceList,
  _getProvinceCityList,
  _getCityCountyList,
  _getSubmitUser,
  _getgetinfoById,
  _download,
} from '@/api/province'
import Diaol from "./dialo.vue";

export default {
    components: {
    Diaol,
  },
  data() {
    return {
      dialogVisible: false,
      btnDisab:false,
      dataAdd: [],
      swith:"",
      formInline: {
        name: '', //主体名称
        organType: '', //主体机构类型
        address: '', //详细地址
        contactName: '', //联系人姓名
        contactPhone: '', //联系人手机号
        email: '', //邮箱
        code: '', //社会信用代码
        regionCode: '', //市
        provinceid: '', //活动区域
        cityid: '', //省
        businessLicenseOssIds:[],//营业执照回显数组
        securityqualificationOssIds:[],//安防回显数组
      },
      ossIds:[],//提交的时候图片的ossid数组
      cityCountylist: [],
      provinceCitylist: [],
      provinceList: [],
      imgsList: [], // 文件上传列表
      newimgsList: [], // 文件上传列表
      dialogImageUrl: '', //查看大图
      disab: false,
    }
  },

  created() {
    this.getcode()
    this.swith = this.$route.query.swith
    //获取省的数据
    _getProvinceList({}).then(res => {
      this.provinceList = res.data
    })
    // 回显数据
    _getgetinfoById({id:this.$route.query.id}).then(res => {
      if (res.code == '1') {
          this.formInline = Object.assign(res.data)
           res.data.businessLicenseOssIds.forEach((item)=>{
            this.imgsList.push({url:"/ananops/api/v1/pub/file/download?ossId="+`${item.ossId}`+'&type=pc'})
          })
           res.data.securityqualificationOssIds.forEach((item)=>{
            this.newimgsList.push({url:"/ananops/api/v1/pub/file/download?ossId="+`${item.ossId}`+'&type=pc'})
          })
          this.disab = true
          _getProvinceCityList({ parent_id: this.formInline.provinceid }).then(
            res => {
              this.provinceCitylist = res.data
            },
          )
          _getCityCountyList({ parent_id: this.formInline.cityid }).then(
            res => {
              this.cityCountylist = res.data
            },
          )
        }
      })
  },
  methods: {
    changeProvince(e) {
      //获取市的数据
      _getProvinceCityList({ parent_id: this.formInline.provinceid }).then(
        res => {
          this.provinceCitylist = res.data
        },
      )
    },
    changeProvinceCity(e) {
      //获取区的数据
      _getCityCountyList({ parent_id: this.formInline.cityid }).then(res => {
        this.cityCountylist = res.data
      })
    },
    
    getcode() {
      let from = {
        para: 'jglx',
      }
      _getcode(from).then(res => {
        this.dataAdd = res.data
      })
    },
    // 点击大图显示
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    // 营业执照成功返回的数据
    successFile(response, file, fileList){
      this.formInline.businessLicenseOssIds.push(response.data.ossId)
    },
    // 营业执照图片上传接口
    changeFile(file, fileList) {
    },
    // 营业执照移除图片
    removeFile(file, fileList) {
      this.formInline.businessLicenseOssIds.splice(file.response.data.ossId,1)

    },
    // 安防成功返回的数据
    newsuccessFile(response, file, fileList){
      this.formInline.securityqualificationOssIds.push(response.data.ossId)
    },
    // 安防图片上传接口
    newchangeFile(file, fileList) {
    },
    // 安防移除图片
    newremoveFile(file, fileList) {
      this.formInline.securityqualificationOssIds.splice(file.response.data.ossId,1)
    },
    // 确定
    agree(){
      let form = {
        checkStatus:1,
        id:this.$route.query.id
      }
       this.$confirm('是否确定同意', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          _confirm(form).then((res)=>{
            if(res.code == 1){
              this.$message(res.msg)
              this.$router.push({
              path: "/main/platform/platforms",
            });
            }
      })
        })
    },
    // 拒绝
    refused(){
      this.$refs.diaol.init(this.$route.query.id);
    },
  },
}
